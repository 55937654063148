:root {
  /* colours */
  --var-col-main: #f5ed85;
  --var-col-victim: #fafafa;
  --var-col-harasser: #382424;
  --var-col-text: #2e4f4f;
  --var-col-text-light: #6f8f8f;

  /* rules */
  color: var(--var-col-text);
  font-size: 16px;
}
body {
  font-family: "Inconsolata", serif;
  color: 1px solid var(--var-col-text);
  /* keep here as a reference: */
  /* font-variation-settings: "wght" 597.02, "wdth" 100; */
  /* From: https://v-fonts.com/fonts/inconsolata */
  background: rgb(245, 237, 133);
  background: linear-gradient(
    0deg,
    rgba(245, 237, 133, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

a {
  color: inherit;
}

h5 {
  font-size: 0.75rem;
  text-transform: uppercase;
  margin: 0.625rem 0;
}

h6 {
  font-size: 0.625rem;
  text-transform: uppercase;
  margin: 0.625rem 0;
}

header {
  /* background-color: rgba(255, 255, 255, 0.65);
  height: 4rem;
  width: 100%;
  overflow: hidden; */
}

span.close {
  cursor: pointer;
  display: inline-block;
  position: relative;
  left: 5px;
  top: 0.5px;
}

span.close::before {
  content: "";
  background-color: var(--var-col-text);
  height: 3px;
  width: 17px;
  display: block;
  transform: rotate(-45deg);
}

span.close::after {
  content: "";
  background-color: var(--var-col-text);
  height: 3px;
  width: 17px;
  display: block;
  transform: rotate(45deg);
  top: -2.6px;
  position: relative;
}

.for-test-purposes {
  display: none !important;
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}

.cursor-pointer {
  cursor: pointer;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.noselect,
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.blur-bg {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.main {
  width: 100vw;
  height: 100vh;
}

.pixi-container {
  cursor: grab;
}

.pixi-container:active {
  cursor: grabbing;
}

.visualization-tools {
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.75rem;
  box-sizing: border-box;
}

.visualization-tools .top,
.visualization-tools .middle,
.visualization-tools .bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tool {
  width: calc(100% / 3);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.tool:first-child {
  align-items: flex-start;
}

.tool:last-child {
  align-items: flex-end;
}

.tool > div {
  text-align: center;
}

.btn {
  height: 3.625rem;
  width: 3.625rem;
  margin: 0 0.4375rem;
  background-color: var(--var-col-victim);
  border: 1px solid var(--var-col-text);
  border-radius: 50%;
  font-size: 1.35rem;
  font-family: inherit;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.knots-info-and-cards .btn {
  width: fit-content;
  height: 3rem;
  border-radius: 4px;
  padding: 0 1rem 0.1rem 1rem;
  font-size: 1rem;
}

.btn[disabled] {
  cursor: not-allowed;
}

.tool > * {
  pointer-events: all;
}

.grid-end {
  /* grid-column-end: 25; */
}

.main.harasser .visualization-tools {
  color: var(--var-col-victim);
}

.visualization-tools #victims,
.visualization-tools #incidents,
.visualization-tools #harassers {
  cursor: pointer;
}

.switch {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: calc(1.375rem - 0px);
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--var-col-victim);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 1px solid var(--var-col-text);
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.625rem;
  width: 1.625rem;
  left: -2px;
  top: -3px;
  background-color: var(--var-col-victim);
  transition: 0.4s;
  border: 1px solid var(--var-col-text);
  box-sizing: border-box;
}

input:checked + .slider {
  background-color: var(--var-col-text-light);
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(1.625rem);
  -ms-transform: translateX(1.625rem);
  transform: translateX(1.625rem);
}

/* Rounded sliders */
.slider.round {
  border-radius: 2.125rem;
}

.slider.round:before {
  border-radius: 50%;
}

/* filter */

/* indivisual knots and cards */
.knots-info-and-cards {
  pointer-events: none;
  width: 100vw;
  height: 100vh;
  cursor: pointer;
  transform-origin: 0 0;
}

.knots-info-and-cards .g-master {
  position: relative;
  pointer-events: none;
}

.knots-info-and-cards .item {
  opacity: 0.5;
  transition: transform 0.5s ease;
}

.knots-info-and-cards .item:last-child {
  opacity: 1;
}

g.node.is-playing path {
  stroke: red !important;
}
.knots-info-and-cards .item button {
  pointer-events: none;
}

.knots-info-and-cards .item:last-child button {
  pointer-events: all;
}

.individual-knot .txt-line {
}

.annotation-group .show-bg .annotation-note-bg {
  fill-opacity: 0.4;
  fill: var(--var-col-victim);
  stroke: var(--var-col-text);
  stroke-width: 0.5;
  rx: 1px;
}

.knots-info-and-cards .g-master div:last-of-type .show-bg .annotation-note-bg {
  fill-opacity: 1;
}

.annotation-note-title {
  margin: 0;
  font-size: 0.3rem;
  text-transform: uppercase;
}

.annotation-note-label {
  margin: 0;
  font-size: 0.5rem;
}

#annotations-layer text,
#annotations-layer tspan {
  fill: var(--var-col-text);
}
#annotations-layer circle,
#annotations-layer path {
  stroke: var(--var-col-text);
}

.harasser #annotations-layer text,
.harasser #annotations-layer tspan {
  fill: var(--var-col-victim);
}
.harasser #annotations-layer circle,
.harasser #annotations-layer path {
  stroke: var(--var-col-victim);
}

path.subject {
  fill: none;
  pointer-events: none;
}