.main {
  max-width: 100%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  overflow: hidden;
}
.footer {
  background-color: var(--var-col-victim);
  padding: 50px 0 50px;
  width: 100%;
}

.footer > .main {
  column-gap: 15px;
}

.home-header {
  display: grid;
  height: 50vh;
  width: 100%;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr 2fr;
  column-gap: 15px;
  grid-column: 1 / 7;
}

.main > p {
  grid-column: 2 / 6;
}

.main h1 {
  font-size: 24px;
  font-weight: 600;
}

.main p {
  font-size: 16px;
}

p.big {
  font-size: 18px;
}

.tin-button {
  height: 36px;
  width: 100%;
  max-width: 180px;
  border-radius: 0;
  border: none;
  background-color: var(--var-col-victim);
  border: 1px solid var(--var-col-text);
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: var(--var-col-text);
}
.tin-button:hover {
  background-color: var(--var-col-main);
}

.tin-button-big {
  max-width: 100%;
}

.big-explore-button {
  grid-column: 2 / span 4;
  margin: 2em 0 3em;
}

.credits {
  grid-column: 2 / span 4;
  grid-row: 1;
}

.credits ul {
  padding: 0;
  margin: 0;
}

.credits ul li {
  list-style: none;
  margin-bottom: 0.5em;
}

.logos {
  grid-column: 2 / span 4;
  grid-row: 2;
}

.logos span {
  display: flex;
  flex-wrap: wrap;
}

.logo {
  width: auto;
  height: 40px;
}
.logo:not(:last-child) {
  margin-right: 20px;
  margin-bottom: 20px;
}

.responsive-message {
  text-align: center;
}

@media (min-width: 410px) {
  .main p {
    font-size: 18px;
  }
}

@media (min-width: 576px) {
  .main {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .main {
    max-width: 720px;
  }
  .main h1 {
    font-size: 2em;
  }
  .main p {
    font-size: 24px;
  }
  p.big {
    font-size: 30px;
  }
  .big-explore-button {
    grid-column: 3 / span 2;
    margin: 50px 0;
  }
  .credits {
    grid-column: 2 / span 2;
    grid-row: 1;
  }
  .logos {
    grid-column: 4 / span 2;
    grid-row: 1;
  }
  .responsive-message {
    display: none;
  }
}

@media (min-width: 992px) {
  .main {
    max-width: 960px;
  }
  .main h1 {
    font-size: 2.4em;
  }
  .logos {
    grid-column: 4 / span 3;
    grid-row: 1;
  }
}

@media (min-width: 1200px) {
  .main {
    max-width: 1140px;
  }
  .main h1 {
    font-size: 3em;
  }
}
