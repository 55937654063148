.item.show-full-report {
    /* transform-origin: -50% -22px !important; */
}

.item > *:not(.full-report) {
    display: flex;
}

.item.show-full-report > *:not(.full-report) {
    display: none;
}

.item .full-report {
    display: none;
}

.item.show-full-report .full-report {
    display: block;
}

.item h6 {
    margin: 0;
    font-size: 0.6rem;
    text-transform: uppercase;
}

.item h4 {
    margin: 1rem 0 .1rem;
}
  
.item p {
    margin: 0;
    font-size: 1rem;
}

.properties {
    display: flex;
    justify-content: center;
    padding-bottom: 4px;
}

.property {
    border: 1px solid var(--var-col-text);
    width: fit-content;
    margin: 0 2px;
    padding: 0.2rem 0.3rem;
    /* background-color: var(--var-col-victim); */
    background-color: rgba(250, 250, 250, 0.75);
}

.full-report {
  background-color: white;
  border: 1px solid var(--var-col-text);
  border-radius: 4px;
  width: 480px;
  max-width: 80vw;
  pointer-events: all;
  cursor: default;
  /* overflow: hidden; */
}

.full-report > * {
    border-bottom: 1px solid var(--var-col-text);
    max-width: 100%;
    box-sizing: border-box;
    height: fit-content;
    padding: 0rem 0.5rem 1rem;
}

.full-report > *:last-child {
    border-bottom: none;
}

.full-report header {
    background-color: transparent;
    padding-top: .2rem;
    padding-bottom: .2rem;
    padding-right: 1.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    cursor: move;
    text-overflow: ellipsis;
    overflow: hidden;
}
.full-report header span.close {
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: unset;
    right: 3px !important;
}
.full-report header span.close::before {
    content: '';
    background-color: var(--var-col-text);
    height: 3px;
    width: 17px;
    display: block;
    transform: rotate(-45deg);
}

.full-report header span.close::after {
    content: '';
    background-color: var(--var-col-text);
    height: 3px;
    width: 17px;
    display: block;
    transform: rotate(45deg);
    top: -2.6px;
    position: relative;
}

.full-report .coding > div.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 1rem;
    margin-top: .5rem;
}

.full-report .coding img {
    max-width: 100%;
    border: 1px solid var(--var-col-text);
}

.full-report .coding > div.grid > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.full-report .coding .property {
    border: none;
    background-color: transparent;
}

.full-report .answer {
    height: 480px;
    overflow-y: scroll;
}

.full-report .answer span {
    text-decoration: underline;
}