.loading {
  width: 100vw;
  height: 100vh;
  background-color: var(--var-col-main);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading span {
  animation-name: blink;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.loading span:nth-child(1) {
  animation-delay:0.5s
}
.loading span:nth-child(2) {
  animation-delay:1s
}
.loading span:nth-child(3) {
  animation-delay:1.5s
}

@keyframes blink {
  0% {opacity: 1;}
  50% {opacity: 0;}
  100% {opacity: 1;}
}