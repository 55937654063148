/* @import url('https://fonts.googleapis.com/css?family=Inconsolata:400,700&display=swap&subset=latin-ext,vietnamese'); */

@font-face {
	src: url('./fonts/Inconsolata-VF.woff2') format('woff2-variations');
	font-family:'Inconsolata';
	font-style: normal;
}

body {
  margin: 0;
  padding: 0;
}
